import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { useInView } from 'framer-motion';

import styles from './AnimateIn.module.css';

const AnimateInItem = ({
  renderAsTag = 'div',
  threshold = 0.35,
  className,
  style,
  children,
}) => {
  const ref = useRef(null);
  const inView = useInView(ref, { amount: threshold, once: true });

  const Tag = renderAsTag;

  useEffect(() => {
    if (inView && ref.current) {
      ref.current.classList.add(styles.inView);
    }
  }, [inView]);

  return (
    <Tag ref={ref} className={cn(className, styles.root)} style={style}>
      {children}
    </Tag>
  );
};

const AnimateIn = ({
  renderAsTag = 'div',
  children,
  threshold = 0.35,
  className,
  style,
}) => {
  return (
    <>
      {React.Children.map(children, (child) => (
        <AnimateInItem
          renderAsTag={renderAsTag}
          threshold={threshold}
          className={className}
          style={style}
        >
          {child}
        </AnimateInItem>
      ))}
    </>
  );
};

AnimateIn.propTypes = {
  renderAsTag: PropTypes.oneOf(['div', 'section', 'article', 'ol', 'ul']),
  children: PropTypes.node.isRequired,
  threshold: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default AnimateIn;
