import React from 'react';
import cn from 'classnames';

import { colorMap } from '@constants/colors';

import ThirdPartyLogo from '@components/ThirdPartyLogo';
import TextRenderer from '@components/TextRenderer';
import Button from '@components/Button';
import Illustration from '@components/Illustration';
import TickList from '@components/TickList';

import { Terminal } from '../Terminal/Terminal';

import Gradient from './Gradient';
import SimpleGradient from './SimpleGradient';

import interactionHooks from '@util/interactions';

import measureStyles from '@styles/utilities/measures.module.css';
import textStyles from '@styles/textStyles.module.css';

import * as styles from './SecondaryHero.module.css';
import * as formatHeroStyles from './FormatHero.module.css';
import * as integrationHeroStyles from './IntegrationHero.module.css';

import StaggerAnimateIn from '@components/StaggerAnimateIn';

const SECONDARY_HERO_ACTIONS = [
  {
    label: 'Start your free trial',
    type: 'primary',
    href: 'https://app.cloudsmith.com/signup/',
    isStartFreeTrial: true,
  },
  {
    label: 'Talk to sales',
    type: 'secondary',
    interaction: 'openChat',
  },
];

export const SecondaryHeroCallToAction = ({
  actions = SECONDARY_HERO_ACTIONS,
  colorScheme = 'accent',
  className,
}) => {
  return (
    <div className={cn(styles.callToActionButtons, className)}>
      {(actions.length > 0 ? actions : SECONDARY_HERO_ACTIONS).map(
        (action, i) => {
          const interaction = action.interaction
            ? interactionHooks[action.interaction]()
            : null;
          return (
            <Button
              key={i}
              size="large"
              colorScheme={colorScheme}
              withArrow
              variant={action.type}
              href={action.href}
              onClick={interaction}
              trackingScope="Hero"
              isStartFreeTrial={action.isStartFreeTrial ?? null}
            >
              {action.label}
            </Button>
          );
        },
      )}
    </div>
  );
};

export const BaseSecondaryHero = React.forwardRef(
  (
    {
      eyebrow,
      className,
      heading,
      subheadingChildren,
      headingSize = 'large',
      children,
      media,
      mediaClassName,
      markerColor,
      theme = 'light',
      gradient,
      flipGradient = false,
      minOverlap,
      minHeight,
      backgroundIllustration,
      backgroundImage,
    },
    ref,
  ) => {
    const HeadlineTag = 'h2';

    const rootClassNames = cn(
      styles.root,
      {
        [styles.themeLight]: theme === 'light',
        [styles.themeDark]: theme === 'dark',
        [styles.themeNone]: theme === 'none',
        [styles.flipGradient]: flipGradient,
        [styles.headingSizeSmall]: headingSize === 'small',
        [styles.noChildren]: !children,
        [styles.hasBackgroundIllustration]: backgroundIllustration,
      },
      className,
    );

    return (
      <section
        ref={ref}
        className={rootClassNames}
        style={{
          '--section-marker-color': markerColor && colorMap[markerColor],
          '--hero-min-overlap': minOverlap,
          '--hero-min-height': minHeight,
          '--hero-illustration-overlap': !media ? '0px' : null,
        }}
      >
        <div className={styles.headerContainer}>
          {gradient && (
            <div className={styles.gradient} aria-hidden>
              <Gradient fill={gradient} />
            </div>
          )}

          {backgroundIllustration && (
            <div className={styles.backgroundIllustration} aria-hidden>
              <Illustration illustration={backgroundIllustration} />
            </div>
          )}

          {backgroundImage && (
            <div
              className={styles.backgroundImage}
              aria-hidden
              style={{ backgroundImage: `url(${backgroundImage}` }}
            />
          )}

          <div className={styles.header}>
            {eyebrow && (
              <p className={cn(textStyles.eyebrow, styles.eyebrow)}>
                {eyebrow}
              </p>
            )}
            <TextRenderer
              text={heading}
              as={HeadlineTag}
              className={cn(styles.headline, textStyles.headlineXL)}
            />

            {subheadingChildren && (
              <div className={styles.subheading}>{subheadingChildren}</div>
            )}
          </div>
        </div>
        {media && (
          <figure className={cn(styles.media, mediaClassName)}>{media}</figure>
        )}
        {children && (
          <div aria-hidden={!!children || null} className={styles.children}>
            {children}
          </div>
        )}
      </section>
    );
  },
);

BaseSecondaryHero.displayName = 'BaseSecondaryHero';

export const SecondaryHeroWithIllustration = ({
  eyebrow,
  heading,
  text,
  listItems,
  illustration,
  markerColor,
  theme = 'light',
  gradient,
  flipGradient,
}) => {
  return (
    <BaseSecondaryHero
      eyebrow={eyebrow}
      heading={heading}
      text={text}
      listItems={listItems}
      illustration={illustration}
      markerColor={markerColor}
      theme={theme}
      gradient={gradient}
      flipGradient={flipGradient}
      media={<Illustration illustration={illustration} />}
    >
      {text && (
        <p className={(textStyles.bodyM, measureStyles.medium, styles.intro)}>
          {text}
        </p>
      )}
      {listItems && <TickList className={cn(styles.list)} items={listItems} />}
    </BaseSecondaryHero>
  );
};

export const FormatHeroWithTerminal = ({
  eyebrow,
  heading,
  illustration,
  markerColor,
  gradient,
  text,
  flipGradient,
  commands,
  terminalGradient,
  logo,
  withActions,
}) => {
  const media = (
    <div
      className={formatHeroStyles.terminalWrapper}
      style={{
        '--terminal-gradient-color':
          terminalGradient && colorMap[terminalGradient],
      }}
    >
      <Terminal
        commands={commands}
        actions={withActions ? SECONDARY_HERO_ACTIONS : null}
      />
    </div>
  );

  return (
    <BaseSecondaryHero
      eyebrow={eyebrow}
      heading={heading}
      illustration={illustration}
      markerColor={markerColor}
      theme="dark"
      gradient={gradient}
      flipGradient={flipGradient}
      media={media}
      mediaClassName={formatHeroStyles.media}
      minHeight="400px"
      minOverlap="var(--space-l)"
    >
      {text && (
        <TextRenderer
          className={cn(textStyles.bodyM, measureStyles.medium, styles.intro)}
          text={text}
        />
      )}
      {logo && (
        <ThirdPartyLogo className={formatHeroStyles.logo} logoKey={logo} />
      )}
    </BaseSecondaryHero>
  );
};

export const FormatHeroWithCallToAction = ({
  eyebrow,
  heading,
  illustration,
  markerColor,
  gradient,
  text,
  flipGradient,
  claim,
  logo,
}) => {
  const media = (
    <div className={cn(styles.callToAction, formatHeroStyles.callToAction)}>
      <div className={cn(styles.callToActionIllustration)}>
        <SimpleGradient />
      </div>
      <StaggerAnimateIn>
        <div className={cn(textStyles.headlineL, formatHeroStyles.claim)}>
          {claim}
        </div>
        <SecondaryHeroCallToAction />
      </StaggerAnimateIn>
    </div>
  );

  return (
    <BaseSecondaryHero
      eyebrow={eyebrow}
      heading={heading}
      illustration={illustration}
      markerColor={markerColor}
      theme="dark"
      gradient={gradient}
      flipGradient={flipGradient}
      media={media}
      mediaClassName={formatHeroStyles.media}
      minHeight="400px"
      minOverlap="var(--space-l)"
    >
      {text && (
        <TextRenderer
          className={cn(textStyles.bodyM, measureStyles.medium, styles.intro)}
          text={text}
        />
      )}
      {logo && (
        <ThirdPartyLogo className={formatHeroStyles.logo} logoKey={logo} />
      )}
    </BaseSecondaryHero>
  );
};

export const FormatHeroWithIllustration = ({
  eyebrow,
  heading,
  markerColor,
  gradient,
  text,
  flipGradient,
  illustration,
  logo,
}) => {
  return (
    <BaseSecondaryHero
      eyebrow={eyebrow}
      heading={heading}
      illustration={illustration}
      markerColor={markerColor}
      theme="dark"
      gradient={gradient}
      flipGradient={flipGradient}
      media={<Illustration illustration={illustration} />}
      mediaClassName={formatHeroStyles.media}
      minHeight="400px"
      minOverlap="var(--space-l)"
    >
      {text && (
        <TextRenderer
          className={cn(textStyles.bodyM, measureStyles.medium, styles.intro)}
          text={text}
        />
      )}
      {logo && (
        <ThirdPartyLogo className={formatHeroStyles.logo} logoKey={logo} />
      )}
    </BaseSecondaryHero>
  );
};

export const IntegrationPageHero = ({
  eyebrow,
  heading,
  illustration,
  markerColor,
  gradient,
  text,
  flipGradient,
  claim,
  logo,
}) => {
  const media = (
    <div className={cn(integrationHeroStyles.callToAction)}>
      <div className={cn(integrationHeroStyles.illustration)}>
        <SimpleGradient />
      </div>
      {logo && (
        <ThirdPartyLogo className={integrationHeroStyles.logo} logoKey={logo} />
      )}
      <StaggerAnimateIn>
        <div className={textStyles.headlineL}>{claim}</div>
        <SecondaryHeroCallToAction colorScheme="light" />
      </StaggerAnimateIn>
    </div>
  );

  return (
    <BaseSecondaryHero
      eyebrow={eyebrow}
      heading={heading}
      headingSize="small"
      illustration={illustration}
      markerColor={markerColor}
      theme="light"
      gradient={gradient}
      flipGradient={flipGradient}
      media={media}
      mediaClassName={integrationHeroStyles.media}
      minHeight="450px"
      minOverlap="var(--space-l)"
    >
      {text && (
        <TextRenderer
          className={(textStyles.bodyM, measureStyles.medium, styles.intro)}
          text={text}
        />
      )}
    </BaseSecondaryHero>
  );
};

export const BlogLandingPageHero = ({
  className,
  eyebrow,
  heading,
  illustration,
  markerColor,
  gradient,
  text,
  flipGradient,
  backgroundImage,
  backgroundIllustration,
}) => {
  return (
    <BaseSecondaryHero
      className={className}
      eyebrow={eyebrow}
      heading={heading}
      illustration={illustration}
      markerColor={markerColor}
      theme="dark"
      backgroundImage={backgroundImage}
      backgroundIllustration={backgroundIllustration}
      gradient={gradient}
      flipGradient={flipGradient}
      minHeight="450px"
      minOverlap="var(--space-l)"
    >
      {text && (
        <TextRenderer
          className={(textStyles.bodyM, measureStyles.medium, styles.intro)}
          text={text}
        />
      )}
    </BaseSecondaryHero>
  );
};
