import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

TimeAgo.addLocale(en);

export const timeAgo = (input) => {
  const formatter = new TimeAgo('en-GB');
  return formatter.format(new Date(input));
};

export const splitTimeAgo = (input) => {
  const formatter = new TimeAgo('en-GB');
  const formatted = formatter.format(new Date(input));

  const [time, ...unit] = formatted.split(' ');

  return {
    time,
    unit: unit.join(' '),
  };
};

/**
 * Subtracts a year from a date
 */
export const oneYearAgo = (d = new Date()) => {
  const date = new Date(d);
  date.setFullYear(d.getFullYear() - 1);
  return date;
};

export const formatDate = (d) => {
  const date = new Date(d);

  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

export const readableDate = (d) => {
  const date = new Date(d);

  const month = date.toLocaleString('en-GB', { month: 'short' });

  return `${month} ${date.getDate()} ${date.getFullYear()}`;
};

export const maybeDate = (d) => {
  if (d === null || d === undefined || d === '') return null;

  return new Date(d).toISOString();
};

/**
 * Formats a date for ICS file format
 *
 * @param {Date|string} input
 * @returns {string}
 */
export const formatDateForICS = (input) => {
  const date = new Date(input);

  return date.toISOString().replace(/-|:|\.\d{3}/g, '');
};
