import Illustration, { QuarterTurn } from '@components/Illustration/blocks';

export default function Gradient({ fill = 'purple' }) {
  return (
    <Illustration width={1200} height={1200} size={2800}>
      <QuarterTurn
        x={-0.35}
        y={0}
        fill={fill}
        horizontal="left"
        vertical="up"
      />
    </Illustration>
  );
}
