import SanityImage from '@components/SanityImage';

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { themeMap } from '@constants/colors';

import StaggerAnimateIn from '@components/StaggerAnimateIn';
import TickList from '@components/TickList';

import textStyles from '@styles/textStyles.module.css';

import Illustration from '@components/Illustration';
import Gradient from './Gradient';

import * as styles from './BaseCallToActionCard.module.css';

const BaseCallToActionCard = ({
  actions,
  claim,
  subline,
  illustration = 'Simple',
  theme = 'light',
  gradient,
  flipGradient = false,
  bleed = false,
  align = 'left',
  verticalAlign = 'bottom',
  size = 'medium',
  listItems,
  eyebrow,
  image,
}) => {
  const headlineClasses = cn({
    [textStyles.headlineXL]: size === 'medium' || size === 'large',
    [textStyles.headlineL]: size === 'small',
  });

  const sublineClasses = cn(
    {
      [textStyles.bodyL]: size === 'medium',
      [textStyles.bodyL]: size === 'large',
      [textStyles.bodyM]: size === 'small',
    },
    styles.subline,
  );

  return (
    <div
      className={cn(
        styles.root,
        {
          [styles.bleed]: bleed,
          [styles.alignRight]: align === 'right',
          [styles.sizeSmall]: size === 'small',
          [styles.sizeLarge]: size === 'large',
          [styles.verticalAlignTop]: verticalAlign === 'top',
          [styles.flipGradient]: flipGradient,
        },
        themeMap[theme].className,
      )}
      style={{
        '--card-background-color': themeMap[theme].background || null,
        '--card-text-color': themeMap[theme].text || null,
      }}
    >
      {gradient ? (
        <div className={styles.gradient} aria-hidden>
          <Gradient fill={gradient} />
        </div>
      ) : (
        <div className={cn(styles.illustration)}>
          <Illustration illustration={illustration} />
        </div>
      )}
      <div className={cn(styles.overlay)} aria-hidden />
      <div className={cn(styles.content)} threshold={0.9}>
        <StaggerAnimateIn className={cn(styles.inner)}>
          {eyebrow && <div className={cn(textStyles.eyebrow)}>{eyebrow}</div>}
          <div className={headlineClasses}>{claim}</div>
          <div className={sublineClasses}>{subline}</div>
          {listItems && (
            <div className={styles.list}>
              <TickList
                items={listItems}
                style={{ '--tick-icon-color': 'currentColor' }}
              />
            </div>
          )}
          <div className={cn(styles.actions)}>{actions}</div>
        </StaggerAnimateIn>
      </div>
      {image && image.image && (
        <div className={cn(styles.image)}>
          <SanityImage asset={image.image} />
        </div>
      )}
    </div>
  );
};

BaseCallToActionCard.propTypes = {
  props: PropTypes.object,
};

export default BaseCallToActionCard;
